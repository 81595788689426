import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Hidden from '@material-ui/core/Hidden';
import ProgressiveImage from 'react-progressive-image';

import Jumbotron from '../../components/Jumbotron';
import AtriasCorner from '../../components/Base/AtriasCorner';
import AtriasButton from '../../components/Base/AtriasButton';

import JobCard from './JobCard';
import styles from './style.module.scss';
import Background from '../../assets/images/backgrounds/jobs_top.jpg';
import Placeholder from '../../assets/images/backgrounds/jobs_top_min.jpg';
import CardBackground from '../../assets/images/backgrounds/jobs_card.jpg';
import CardPlaceholder from '../../assets/images/backgrounds/jobs_card_min.jpg';

const Jobs = () => {
  const { t } = useTranslation('jobs');

  const handleClick = () => {
    window.open(t("https://jobs.fluvius.be/job/Spontane-sollicitatie/518202402/"), '_blank');
  };

  const jobs = [

    {
      title: 'jobs.eight.title',
      content: 'jobs.eight.content',
      url: 'jobs.eight.url',
    },
    {
      title: 'jobs.nine.title',
      content: 'jobs.nine.content',
      url: 'jobs.nine.url',
    },
    {
      title: 'jobs.ten.title',
      content: 'jobs.ten.content',
      url: 'jobs.ten.url',
    }
  ];

  return (
    <Grid container direction="column">
      <Helmet>
        <title>{`${t('jumbotron')} | Atrias`}</title>
        <meta name="description" content={t('meta-description')} />
      </Helmet>
      <Jumbotron title={t('jumbotron')} background={Background} placeholder={Placeholder} />
      <Grid item container xs={12} justify="center">
        <Grid item container justify="center" className="content-frame-padded">
          <Grid item className="content-margin">
            <p className={styles.sub__text}>{t('frames.one.sub-title')}</p>
            <h1>{t('frames.one.title')}</h1>
            <p>{t('frames.one.content.one')}</p>
            <p>{t('frames.one.content.two')}</p>
          </Grid>
          <Grid
            container
            xs={12}
            item
            direction="row"
            className="m-t-56 sector-doc-margin"
          >
            {jobs.map((item, index) => (
              // We disable the eslint errors as there is no other unique key we can use
              // eslint-disable-next-line react/no-array-index-key
              <Grid key={index} item xs={12} sm={6} md={5} lg={4} className={styles['document-card']} alignItems="stretch">
                <JobCard
                  title={item.title}
                  content={item.content}
                  image={item.image}
                  url={item.url}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <ProgressiveImage src={CardBackground} placeholder={CardPlaceholder}>
        {(image) => (
          <Grid
            item
            container
            xs={12}
            justify="center"
            style={{ backgroundImage: `url(${image})` }}
            className="background-frame position-relative background-frame--margin"
          >
            <Grid
              item
              container
              justify="flex-start"
              className="content-frame-padded"
            >
              <Hidden smDown>
                <AtriasCorner color="white" size={150} />
              </Hidden>
              <Grid item xs={12} md={7} lg={6}>
                <Card className="atrias-info-card card-large position-relative">
                  <CardContent className="card-large-content">
                    <h1>{t('frames.two.title')}</h1>
                    <p>{t('frames.two.content')}</p>
                  </CardContent>
                  <CardActions className="card-large-actions">
                    <AtriasButton
                      fill
                      label={t('frames.two.action')}
                      handleClick={handleClick}
                    />
                  </CardActions>
                  <AtriasCorner color="red" size={40} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ProgressiveImage>

    </Grid>

  );
};

export default Jobs;
